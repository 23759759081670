.home-solution {
    height: 50vh;
    width: 95%;
    background: rgb(241, 240, 240);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-top: 5vh;
    box-shadow: 20px 10px 50px #d9d9d9, 0px 0px 20px #ffffff;
    /* border: 0.5px solid black; */
}

.home-solution-left-container {
    height: 100%;
    width: 60%;
    /* background: rgb(151, 52, 52); */
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-solution-left-warp {
    /* change it to fit-content */
    height: 100%;
    width: 80%;
    /* background: rgb(160, 160, 160); */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    /* gap: 10px; */
}

.home-solution-title {
    width: 100%;
    height: 20%;
    /* background: rgb(14, 123, 185); */
    display: flex;
    align-items: flex-start;
    font: var(--primary-font);
    /* font-style: normal; */
    font-weight: 900;
    font-size: var(--font-size-1);
    color: #000000;
    text-align: left;
}

.home-solution-content {
    width: 80%;
    height: 50%;
    display: flex;
    text-align: justify;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: flex-start;
    /* background: rgb(14, 185, 176); */
    font: var(--primary-font);
    /* font-style: normal; */
    font-weight: 400;
    font-size: var(--font-size-3);
    color: #000000;
}

.home-solution-right-container {
    height: 100%;
    width: 40%;
    border-radius: 0 10px 10px 0;
    background: rgb(255, 255, 255);
}

.home-solution-image{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

@media only screen and (max-device-width: 760px) {
    .home-solution {
        flex-direction: column;
        height: fit-content;
    }
    .home-solution-left-container{
        width: 100%;
    }
    .home-solution-left-warp{
        width: 100%;
        gap: 25px;
        align-items: center;
    }
    .home-solution-title{
        text-align: center;
        width: fit-content;
        /* background: #000; */
    }
    .home-solution-content{
        align-items: center;
    }

    .home-solution-right-container{
        width: 100%;
    }
}